Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.addAddressAPiEndPoint = "/address/addresses";
exports.addAddressApiMethod = "POST";
exports.addAddressApiContentType = "application/json";
exports.getAddressApiEndPoint = "/address/addresses";
exports.getAddressApiMethod = "GET";
exports.getAddressApiContentType = "application/json";
exports.txtAddAddressTitle = "Add Address";
exports.txtLabelAddress = "Address";
exports.txtLabelAddressType = "Address Type";
exports.txtLabelLat = "Latitude";
exports.txtLabelLng = "Longitude";
exports.errorTitle = "Error";
exports.btnAdd = "Add";
exports.addressListLabel = "Address List";
exports.successMsgTitle = "Add Address"
exports.successMsg = "Added address successfully!"
exports.addressTitle = "Address : ";
exports.addressTypeTitle = "Address Type : ";
exports.latitudeTitle = "Latitude : ";
exports.longitudeTitle = "Longitude : ";
// Customizable Area End
