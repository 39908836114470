import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
} from "react-native";
// Customizable Area End

import Select from "react-select";

import AddAddressController, {
  Props,
  configJSON,
  AdressTypeData
} from "./AddAddressController";

export default class AddAddress extends AddAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <View>
          <View style={styles.bgInputContainer}>
            <Text style={styles.titleInput} testID="latLabel">
              {configJSON.txtLabelLat}
            </Text>
            <TextInput
              testID="latitudeInput" //Merge Engine::From BDS
              style={styles.bgMobileInput} //UI Engine::From Sketch
              placeholder={configJSON.txtLabelLat} //UI Engine::From Sketch
              value={this.state.txtInputLatValue}
              {...this.txtInputLatProps} //Merge Engine::From BDS - {...this.testIDProps}
            />
          </View>
          <View style={styles.bgInputContainer}>
            <Text style={styles.titleInput}>{configJSON.txtLabelLng}</Text>
            <TextInput
              testID="longitudeInput" //Merge Engine::From BDS
              style={styles.bgMobileInput} //UI Engine::From Sketch
              placeholder={configJSON.txtLabelLng} //UI Engine::From Sketch
              value={this.state.txtInputLngValue}
              {...this.txtInputLngProps} //Merge Engine::From BDS - {...this.testIDProps}
            />
          </View>
          <View style={styles.bgInputContainer}>
            <Text style={styles.titleInput}>{configJSON.txtLabelAddress}</Text>
            <TextInput
              testID="addressTextInput" //Merge Engine::From BDS
              style={styles.bgMobileInput} //UI Engine::From Sketch
              placeholder={configJSON.txtLabelAddress} //UI Engine::From Sketch
              value={this.state.txtInputAddressValue}
              {...this.txtInputAddressProps} //Merge Engine::From BDS - {...this.testIDProps}
            />
          </View>
          <View style={styles.bgInputContainer}>
            <Text style={styles.titleInput}>
              {configJSON.txtLabelAddressType}
            </Text>
            <View style={styles.dropdownView}>
              <Select
                data-test-id="addressTypeSelectList"
                options={AdressTypeData}
                defaultValue={AdressTypeData[0]}
                onChange={item =>
                  item && this.handleOnchnageAddressType(item.value)
                }
              />
            </View>
          </View>
          <TouchableOpacity
            style={styles.viewBtn}
            testID="btnSubmit" //Merge Engine::From BDS
            onPress={this.handleSavePressed.bind(this)}
          >
            <Text style={styles.viewBtnText}>{configJSON.btnAdd}</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 80,
    marginLeft: "auto",
    marginRight: "auto",
    width: "75%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "blue",
    zIndex: -1
  },
  viewBtnText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16
  },
  titleInput: {
    color: "#000"
  },
  bgInputContainer: {
    marginBottom: 25
  },
  bgMobileInput: {
    height: 45,
    borderBottomWidth: 0,
    borderColor: "#c9c9c9"
  },
  dropdownView: {
    marginBottom: 10,
    zIndex: 99
  },
});
// Customizable Area End
